/* eslint-disable no-restricted-globals, no-restricted-syntax */
/* global clients */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import authConfig from '../../config/auth';

firebase.initializeApp(authConfig);

// Returns a promise that resolves with an ID token if available.
const getIdToken = () => new Promise(resolve => {
  const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    unsubscribe();
    if (user) {
      user.getIdToken().then(idToken => {
        resolve(idToken);
      }, () => {
        resolve(null);
      });
    }
    else {
      resolve(null);
    }
  });
}).catch(error => {
  console.error(error);
});

self.addEventListener('install', () => {
  console.log('Service Worker installing.');
});

// Let clients know when SW is ready for messages
self.addEventListener('activate', event => {
  console.log('Service Worker activating.');
  event.waitUntil(
    clients.claim().then(() =>
      clients.matchAll().
        then(clients => Promise.all(clients.map(client => client.postMessage('Ready for messages.'))))
    )
  );
});

self.addEventListener('message', event => {
  if (event.data.isCharmedChallenge) {
    const isAuthenticated = Boolean(firebase.auth().currentUser);

    event.ports[0].postMessage({isAuthenticated});
  }
});

const authorizedFetch = event => getIdToken().
  then(idToken => {
    const headers = new Headers();

    for (const [key, val] of event.request.headers.entries()) {
      headers.append(key, val);
    }
    headers.append('Authorization', `Bearer ${idToken}`);
    const authorizedRequest = new Request(event.request, {headers});

    return fetch(authorizedRequest);
  }).
  catch(() => fetch(event.request));

self.addEventListener('fetch', event => {
  const swHostname = self.location.hostname;
  const requestHostname = new URL(event.request.url).hostname;

  if (
    // If same-hostname and localhost...
    swHostname === requestHostname && swHostname === 'localhost' ||
    // Or api hostname and secure...
    requestHostname === `api.${swHostname}` && self.location.protocol === 'https:'
  ) {
    // Try attaching Authorization header
    event.respondWith(authorizedFetch(event));
  }
  else {
    event.respondWith(fetch(event.request));
  }
});
